<script setup lang="ts">
import { computed } from 'vue';
import FormField from '../FormField.vue';
import type { FormData } from '../../types';
import type { Validation } from '@vuelidate/core';

const props = defineProps<{
  formData: FormData;
  v$: Validation;
}>();

const isNit = computed(() => props.formData.idType === '5');
</script>

<template>
  <div class="space-y-6">
    <h2 class="text-xl font-semibold text-gray-900 border-b pb-2">
      Paso 1: Información Personal
    </h2>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
      <FormField 
        label="Tipo de Identificación" 
        required
        :error="v$.idType.$error ? 'Seleccione un tipo de identificación' : ''">
        <select v-model="formData.idType" class="select select-bordered w-full">
          <option value="">Seleccione...</option>
          <option value="2">Cédula de Ciudadanía</option>
          <option value="3">Cédula de Extranjería</option>
          <option value="4">Pasaporte</option>
          <!-- <option value="4">Tarjeta de Identidad</option> -->
          <option value="5">NIT</option>
        </select>
      </FormField>

      <FormField 
        label="Número de Identificación" 
        required
        :error="v$.idNumber.$error ? 'Ingrese un número válido' : ''">
        <div class="flex gap-2">
          <input 
            v-model="formData.idNumber"
            type="number"
            class="input input-bordered w-full"
            :placeholder="isNit ? 'Ej. 901321123' : 'Ej. 12345678'">
          <input
            v-if="isNit"
            v-model="formData.verificationDigit"
            type="number"
            class="input input-bordered w-16"
            placeholder="DV"
            maxlength="1">
        </div>
      </FormField>

      <FormField 
        :label="isNit ? 'Razón Social' : 'Nombres'" 
        required
        :error="v$.name.$error ? 'Este campo es requerido' : ''">
        <input 
          v-model="formData.name"
          type="text"
          class="input input-bordered w-full"
          :placeholder="isNit ? 'Nombre de la empresa' : 'Nombres completos'">
      </FormField>

      <FormField 
        v-if="!isNit"
        label="Apellidos" 
        required
        :error="v$.lastName.$error ? 'Este campo es requerido' : ''">
        <input 
          v-model="formData.lastName"
          type="text"
          class="input input-bordered w-full"
          placeholder="Apellidos completos">
      </FormField>

      <FormField 
        label="Correo Electrónico" 
        required
        :error="v$.email.$error ? 'Ingrese un correo válido' : ''">
        <input 
          v-model="formData.email"
          type="email"
          class="input input-bordered w-full"
          placeholder="correo@ejemplo.com">
      </FormField>
    </div>
  </div>
</template>